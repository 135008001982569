@keyframes showBgOverlay {
	from {
		opacity: 0;
	}

	to {
		opacity: 100%;
	}
}

@keyframes showPopUp {
	from {
		translate: 0px -300px;
	}

	to {
		translate: 0px 0px;
	}
}

.Wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	display: block;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	background-color: #0000006e;
	animation: showBgOverlay 300ms;
}

.WrapperForceFull {
	top: 80px !important;
	height: calc(100% - 80px) !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.ModalDialog {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	padding: 12px;
}

.ModalDialogForceFull {
	padding: 0 !important;
}

.PopUpBox {
	margin: auto;
	/* width: 70%; */
	border-radius: 10px;
	background-color: #fff;
	padding: 20px;
	animation: showPopUp 300ms;
}

.PopUpBoxForceFull {
	margin: 0 !important;
	width: 100% !important;
	height: 100% !important;
	border-radius: 0;
	overflow-y: auto;
}

.Content {
	padding: 12px;
	margin: 0px 12px 12px 12px;
}

.Logo {
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
}

.Logo img {
	width: 50%;
	margin: auto;
}

.InfoBox {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	padding: 15px;
	border-radius: 12px;
	background-color: #eaeaea;
	color: #888;
	margin-bottom: 40px;
}

.InfoBox svg {
	width: 20px;
	height: 20px;
}

.InfoText {
	width: calc(100% - 30px);
}

.RegisterButton {
	width: 100%;
	margin-bottom: 20px;
	margin-top: 20px;
	color: blue;
	cursor: pointer;
	text-align: center;
}

@media (max-width: 992px) {
	.PopUpBox {
		width: 95% !important;
	}

	.PopUpBoxForceFull {
		width: 100% !important;
	}
}