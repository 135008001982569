.InputImage,
.InputImage3,
.InputImage5 {
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
}
.InputImage3 {
	height: unset;
	border-radius: 8px;
	border: 1px solid #ddd;
	box-shadow: 0 0 5px #ddd;
}
.InputImage3 img {
	border-radius: 8px;
}
.InputImage5 {
	position: relative;
}
.InputForm {
	display: none;
}
.InputArea {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	border: 1px dashed #ca0c64;
	display: flex;
	flex-direction: column;
	color: #ca0c64;
	text-align: center;
	justify-content: center;
	gap: 20px;
	font-size: 14px;
	background-color: #fef6f9;
	cursor: pointer;
}
.InputArea:hover {
	background-color: #fcd8e6;
}
.Hidden {
	display: none !important;
}
.InputIcon {
	width: 50px;
	height: 50px;
	margin-top: auto;
	margin-left: auto;
	margin-right: auto;
}
.InputText {
	width: 100%;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
}
.InputPreview {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 10px;
}
.DeleteImage {
	width: unset;
	background-color: white !important;
	color: red !important;
	border-color: red !important;
	margin: auto;
}
.AutoEditImage {
	width: unset;
	background-color: #fff !important;
	color: #6a6a6a !important;
	border-color: #eaeaea !important;
	margin: auto;
	padding: 10px;
	position: absolute;
	left: calc(100% - 75px);
	margin-top: 10px;
}
.DeleteImage:hover {
	background-color: red !important;
	color: white !important;
}
.DeleteImage5 {
	position: absolute;
	right: 0;
	margin: 5px;
	padding: 8px;
	font-size: 17px;
	background-color: #ca0c64;
	border: none;
	color: white;
	border-radius: 8px;
	display: flex;
	cursor: pointer;
}
.FitCover {
	object-fit: cover !important;
}
.PreviewImage {
	width: 100%;
	height: calc(100% - 49px);
	object-fit: contain;
	object-position: center;
	border-radius: 8px;
}
.Pointer {
	cursor: pointer;
}
.PreviewImage100 {
	height: 100%;
}
.AbsoluteImage {
	position: absolute;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
}
