.BgLayout {
	background-image: url("../../../public/images/f7a5377b9b4ce2366a8b4bd6ca4c291a.png");
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	overflow-y: auto;
	padding: 50px 0px;
}

.BoxContent {
	position: absolute;
	width: 50%;
	height: 100%;
	right: 0;
	top: 0;
	overflow-y: auto;
	padding: 20px;
	padding-top: 91px;
	display: flex;
	border-radius: 8px;
	background-color: #fff;
}

.Logo {
	margin: auto;
	width: 200px;
}

.Content {
	width: 434px;
	margin-top: 72px;
	margin-left: auto;
	margin-right: auto;
}

.Title {
	color: #2c2c2c;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.SubTitle {
	color: #8b8b8b;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 10px;
}

.TextCenter {
	text-align: center;
	width: 100%;
	margin: 32px 0px;
}

.GoogleLoginBtn iframe {
	margin: auto !important;
	margin-top: 20px;
	width: unset !important;
	height: unset !important;
}

.ShadowBoxInput1 {
	box-shadow: 0px 20px 15px 0px #ff799333;
}

.ShadowBoxInput2 {
	box-shadow: 0px -20px 15px 0px #ff799333;
}

.BoxInput {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 8px;
	margin-bottom: 30px;
}

.FormFieldInput {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 12px;
	gap: 10px;
	position: relative;
}

.TitleInput {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.TitleInput svg {
	width: 20px;
	height: 20px;
}

.TitleInput svg,
.TitleInput div {
	margin-top: auto;
	margin-bottom: auto;
}

.FieldInput {
	width: 100%;
	border: none !important;
	box-shadow: none !important;
	outline-color: #fff !important;
	text-align: end;
	padding-right: 12px !important;
}

.CapcthaField {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
}

@media (max-width: 992px) {
	.BoxContent {
		width: 65% !important;
	}
}

@media (max-width: 772px) {
	.Content {
		width: fit-content;
	}
}

@media (max-width: 550px) {
	.BoxContent {
		width: 100% !important;
	}
}