/* The switch - the box around the slider */
.Switch {
	position: relative;
	display: inline-block;
	width: 42px;
	height: 23px;
}

/* Hide default HTML checkbox */
.Switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.Slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.Slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 4px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .Slider {
	background-color: #dd0064;
}

input:focus + .Slider {
	box-shadow: 0 0 1px #dd0064;
}

input:checked + .Slider:before {
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px);
}

/* Rounded sliders */
.Slider.Round {
	border-radius: 34px;
}

.Slider.Round:before {
	border-radius: 50%;
}
