@keyframes showBgOverlay {
	from {
		opacity: 0;
	}

	to {
		opacity: 100%;
	}
}

@keyframes showPopUp {
	from {
		translate: 0px -300px;
	}

	to {
		translate: 0px 0px;
	}
}

.Wrapper {
	position: fixed;
	top: 70px;
	left: 0;
	z-index: 888;
	display: none;
	width: 100%;
	height: calc(100% - 71px);
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	background-color: #0000006e;
	animation: showBgOverlay 300ms;
}

.WrapperForceFull {
	padding-bottom: 0px !important;
}

.ModalDialog {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	padding: 12px;
}

.ModalDialogForceFull {
	padding: 0;
}

.PopUpBox {
	margin: auto;
	width: 70%;
	border-radius: 10px;
	background-color: #fff;
	animation: showPopUp 300ms;
}

.PopUpBoxForceFull {
	width: 100% !important;
	margin: 0;
	height: 100%;
	border-radius: 0;
}

.Header {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 12px;
	margin-bottom: 16px;
	padding: 12px;
}

.Header h5 {
	text-align: center;
	font-family: "Inter";
	font-weight: 600;
	font-size: 16px;
	margin: 0;
	margin-left: auto;
}

.CloseButton {
	margin-left: auto;
	margin-right: 12px;
}

.CloseButton svg {
	width: 25px;
	height: 25px;
	color: #ca0c64;
	opacity: 50%;
}

.Content {
	padding: 12px;
	margin: 0px 12px 12px 12px;
}

.ContentForceFull {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.BtnAction {
	display: flex;
	flex-direction: row;
	margin-left: auto;
}

.BtnActionOver {
	display: none;
	flex-direction: row;
	margin-left: auto;
	margin-top: 20px;
}

@media (max-width: 992px) {
	.PopUpBox {
		width: 95% !important;
		overflow-y: auto;
	}

	.PopUpBoxForceFull {
		width: 100% !important;
		margin: 0;
		height: 100%;
		border-radius: 0;
	}

	.Wrapper {
		/* bottom: 70px;
		height: calc(100% - 141px); */
		bottom: 0;
		height: calc(100% - 68px);
		padding-bottom: 68px;
	}

	.WrapperForceFull {
		padding-bottom: 0px !important;
	}

	.ModalDialogForceFull {
		margin-bottom: unset !important;
	}

	.ModalDialog {
		margin-bottom: 68px;
		overflow-y: auto;
	}
}

@media (max-width: 550px) {
	.BtnAction {
		display: none;
	}

	.BtnActionOver {
		display: flex;
	}
}