.BoxBanner {
  position: fixed;
  left: 250px;
  top: 75px !important;
  z-index: 2;
  height: 45px;
  padding-top: unset !important;
  padding-bottom: unset !important;
  display: flex;
  background: linear-gradient(180deg, white, transparent);
}

.Banner {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(45deg, #ffe18a, #ffc7c2);
  padding: 5px 10px;
  font-size: 14px;
  color: #3d3d3d;
}

.Action {
  background-color: #ca0c64;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 13px;
}

@media (max-width: 992px) {
  .BoxBanner {
    top: 70px !important;
    width: calc(100% - 40px);
    padding: unset !important;
    height: 40px;
  }
}