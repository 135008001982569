.Card {
	display: flex;
	padding: 8px 8px 12px 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
	flex-basis: 15.9%;
	/* max-width: 31.8%;  */
}
.Cover {
	/* width: 100%; */
	aspect-ratio: 1;
	height: 120px;
	border-radius: 8px;
	object-fit: cover;
	object-position: center;
	margin-left: auto;
	margin-right: auto;
}
.Title {
	width: 100%;
	color: #2c2c2c;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-left: auto;
	margin-right: auto;
}
.TitleSkeleton {
	border-radius: 8px;
	height: 15px;
	width: 100%;
}
