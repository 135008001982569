.MainContainer {
	margin-top: 70px;
	text-align: center;
	display: flex;
	flex-direction: column;
}
.Title {
	color: #2c2c2c;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 16px;
}
.SubTitle {
	color: #2c2c2c;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 16px;
}
.ContentCard {
	width: 100%;
	margin-top: 44px;
	justify-content: center;
	display: flex;
	flex-direction: row;
	gap: 21px;
}
.CardBox {
	position: relative;
	width: 25%;
	cursor: pointer;
	box-shadow: 0 0 4px #63636394;
	border-radius: 8px;
	background-color: #fff;
}
.Disabled {
	cursor: unset !important;
}
.Badge {
	position: absolute;
	padding: 5px;
	font-size: 15px;
	color: white;
	background-color: red;
	border-radius: 0px 0px 8px 0px;
}
.CardContainer {
	padding: 32px;
	display: flex;
	flex-direction: column;
	text-align: center;
}
.IconCard {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 16px;
}
.IconCard img {
	width: 64px;
}
.TitleCard {
	text-align: center;
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.OpenNote {
	margin-top: 72px;
	text-align: center;
	color: #dd0064;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	cursor: pointer;
	display: block;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
}
.DecorationBox {
	position: absolute;
	top: -364.94px;
	right: 0;
	z-index: -1;
	overflow: hidden;
}
.Decoration {
	width: 837.9px;
	height: 837.9px;
	/* top: -364.94px; */
	/* left: 541.93px; */
	transform: translate(364.94px, 54px);
	background: radial-gradient(
			43.61% 43.61% at 50% 50%,
			rgba(221, 0, 99, 0.06) 0%,
			rgba(221, 0, 100, 0) 100%
		)
		/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	/* radial-gradient(43.61% 43.61% at 50% 50%, color(display-p3 0.792 0.047 0.392 / 0.1) 0%, color(display-p3 0.792 0.047 0.392 / 0) 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
}
.Info{
	text-align: left;
	margin-top: 20px;
	font-size: 13px;
	color: #383838;
}

.EditorContent {
	/* padding: 30px 100px; */
	max-width: 900px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 650px) {
	.ContentCard {
		flex-direction: column;
	}
	.CardBox {
		width: 80%;
		margin-left: 10%;
	}
}

@media (max-width: 772px) {
	.EditorContent {
		padding: 30px;
	}
}
@media (max-width: 992px) {
	.EditorContent {
		margin-top: -30px;
		padding: 30px 40px;
	}
}

