.PopUpContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  container-type: inline-size;
}

/* .PopUpContentMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  gap: 10px;
  height: calc(100% - 35px);
  padding-bottom: 20px;
  overflow-y: auto;
  max-height: 300px;
  min-height: 250px;
} */

.PopUpContentMain {
  height: calc(100% - 35px);
  padding-bottom: 20px;
  overflow-y: auto;
  max-height: 300px;
  min-height: 250px;
}

.PopUpContentMainInner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  gap: 10px;
  overflow-y: auto;
}


.PopUpContentNav {
  margin-top: auto;
  max-height: 100px;
}

.NavButton {
  width: 100%;
  padding: 7px;
  display: flex;
  gap: 7px;
  flex-direction: row;
  border-radius: 20px;
  background-color: rgba(221, 0, 100, 0.08);
  border: 1px solid rgba(221, 0, 100, 0.08);
  color: rgb(202, 12, 100);
  font-weight: 600;
}

.NavButton svg {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 20px;
  height: 20px;
}

.NavButton div {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
}

.VoucherCard {
  border-radius: 10px;
  box-shadow: 0 0 4px 1px #e7e7e7;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: calc(33.3% - 6.7px);
  padding: 15px;
  height: fit-content;
}

.VoucherCard .Icon {
  margin-top: auto;
  margin-bottom: auto;
  width: 30px;
  height: 30px;
}

.VoucherCard .Info {
  margin-top: auto;
  margin-bottom: auto;
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.Info h6 {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.Info svg {
  position: absolute;
  width: 18px;
  height: 18px;
  color: red;
  right: -18px;
  top: -5px;
}

.Info p {
  font-size: 12px;
  color: #888;
}

.CopyBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.CopyBox p {
  margin-top: auto;
  margin-bottom: auto;
  width: calc(100% - 82px);
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Badge {
  padding: 5px;
  border-radius: 10px;
  color: #454545;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  min-width: 71px;
}

.Badge * {
  margin-top: auto;
  margin-bottom: auto;
}

.Alert {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.ForceLeft input {
  text-align: left;
}

.ClassicForm {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.ClassicForm > label {
  width: 20%;
  padding: 10px;
}

.ClassicForm > input {
  width: calc(80% - 10px);
}

.CustomInputDate{
  padding: 12px;
  width: calc(100% - 24px) !important;
  border: 1px solid #eaeaea !important;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px color(display-p3 0 0 0 / 0.1);
}

@media (max-width: 992px) {
  .PopUpContentMain {
    flex-direction: column;
    flex-wrap: unset;
    max-height: unset;
    min-height: unset;
  }

  .VoucherCard {
    width: 100%;
  }

  .Alert {
    top: -12px;
  }
}

@container (max-width: 450px) {
  .ClassicForm{
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
  }
  .ClassicForm > label{
    width: 100%;
  }
  .ClassicForm > input{
    width: calc(100% - 24px);
  }
}