.TitleArea,
.Inline {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
}
.Title {
	display: flex;
	flex-grow: 1;
	margin: 0;
	font-size: 24px;
}
.SubTitle {
	color: #8b8b8b;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 8px;
}
.Inline {
	align-items: flex-start;
}
.BlankData {
	display: flex;
	width: 100%;
	text-align: center;
}
.IconBlank {
	width: 206px;
	height: 206px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 9px;
}
.IconBlank2 {
	transform: rotate(-15deg);
}
.BlankTitle {
	color: #2c2c2c;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 16px;
}
.BlankDesc {
	color: #8b8b8b;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 66px;
}
.PromoArea {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 12px;
	gap: 20px;
}
.CardPromo {
	width: 50%;
	max-width: 50%;
}
.CardPromo .Title {
	max-height: unset;
	overflow: unset;
	max-width: 100%;
	display: inline-block;
	white-space: wrap;
}
.IconPromo {
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background: #e5e5e5;
}
.DecorationBox {
	position: absolute;
	top: -364.94px;
	right: 0;
	z-index: -1;
	overflow: hidden;
}
.Decoration {
	width: 837.9px;
	height: 837.9px;
	/* top: -364.94px; */
	/* left: 541.93px; */
	transform: translate(364.94px, 54px);
	background: radial-gradient(
			43.61% 43.61% at 50% 50%,
			rgba(221, 0, 99, 0.06) 0%,
			rgba(221, 0, 100, 0) 100%
		)
		/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	/* radial-gradient(43.61% 43.61% at 50% 50%, color(display-p3 0.792 0.047 0.392 / 0.1) 0%, color(display-p3 0.792 0.047 0.392 / 0) 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
}
@media (max-width: 550px) {
	.PromoArea {
		flex-direction: column;
	}
	.CardPromo {
		width: 100%;
		max-width: 100%;
	}
}
