.BoxTable{
    width: 100%;
    overflow-x: scroll;
    margin-top: 30px;
}

table{
    border-collapse: collapse;
}

.LabeledColumn{
    display: flex;
    flex-direction: row;
}
.IconLabel{
    width: 50px;
    height: 50px;
    border-radius: 24px;
    border: 1px solid #CA0C6414;
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    object-fit:contain;
    aspect-ratio: 1;
}
.TitleLabel{
    margin-top: auto;
    margin-bottom: auto;
}

/* width */
.BoxTable::-webkit-scrollbar {
    height: 8px;
  }
  
  /* Track */
  .BoxTable::-webkit-scrollbar-track {
    background: #CA0C6414;
  }
  
  /* Handle */
  .BoxTable::-webkit-scrollbar-thumb {
    background: #ff92c5;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  .BoxTable::-webkit-scrollbar-thumb:hover {
    background: #ff0077;
    border-radius: 8px;
  }

  /* Style bordered header only */

.BorderedHeader{
    width: 100%;
    border: none;
}

.BorderedHeader thead{
    background-color: #CA0C6414;
    font-weight: 600;
}

.BorderedHeader thead{
    border-bottom: 1px solid #ff0077;
}

.BorderedHeader tbody tr{
    border-top: 1px solid #ff007734;
}
