.Header {
}
.Loading {
	margin-top: 100px;
	margin-bottom: 100px;
}
.ContentBody {
	display: flex;
	flex-direction: column;
}
.TopInfo {
	display: flex;
	flex-direction: row;
	gap: 26px;
	margin-bottom: 77px;
}
.LeftInfo {
	gap: 20px;
	width: 50%;
}
.Banner {
	border-radius: 16px;
	aspect-ratio: 5 / 3;
	width: 100%;
}
.Banner img {
	border-radius: 16px;
	aspect-ratio: 5 / 3;
	object-fit: cover;
}
.RightInfo {
	width: 50%;
	padding: 24px;
	padding-bottom: 16px;
	box-shadow: 0px 0px 8px 0px #0000001a;
	border-radius: 16px;
	background-color: #fff;
}
.InfoTitle {
	font-family: Inter;
	font-size: 19px;
	font-weight: 600;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}
.Address {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
	margin-bottom: 32px;
	margin-top: 10px;
}
.BoxTime {
	gap: 12px;
	margin-bottom: 32px;
}
.Time {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #000;
	display: flex;
	flex-direction: row;
	grid-area: 10px;
	width: 100%;
}
.Time .Clock {
	margin-left: auto;
}
.FooterInfo {
	margin-top: auto;
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-bottom: 0;
	align-items: center;
}
.Subtitle {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #a7a7a7;
}
.InfoOrg {
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-left: auto;
	align-items: center;
	color: #8a8a8a;
}
.InfoOrg img {
	width: 42px;
	border-radius: 50%;
	aspect-ratio: 1;
}
.Link svg {
	margin-left: auto;
	width: 19.84px;
	height: 19.84px;
	color: #ca0c64;
	cursor: pointer;
}
.LinkWrap{
	display: none;
	margin-bottom: 50px;
}
.LinkWrap svg{
	margin-left: auto;
	width: 19.84px;
	height: 19.84px;
	color: #ca0c64;
	cursor: pointer;
}
.PopupNotify {
	display: flex;
	text-align: center;
	font-size: 16px;
}
.IconPopUp {
	width: 100%;
	height: 100px;
}
.IconPopUp svg {
	width: 100%;
	height: 100px;
	margin-top: 10px;
	margin-bottom: 10px;
}
.Navigator {
	font-family: Inter;
	font-size: 14px !important;
	font-weight: 500;
	line-height: 17px !important;
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}
.Navigator p {
	font-size: 14px !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ChipBox {
	margin-top: 29px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.DecorationBox {
	position: absolute;
	top: -364.94px;
	right: 0;
	z-index: -1;
	overflow: hidden;
}
.Decoration {
	width: 837.9px;
	height: 837.9px;
	/* top: -364.94px; */
	/* left: 541.93px; */
	transform: translate(364.94px, 54px);
	background: radial-gradient(
			43.61% 43.61% at 50% 50%,
			rgba(221, 0, 100, 0.1) 0%,
			rgba(221, 0, 100, 0) 100%
		)
		/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	/* radial-gradient(43.61% 43.61% at 50% 50%, color(display-p3 0.792 0.047 0.392 / 0.1) 0%, color(display-p3 0.792 0.047 0.392 / 0) 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
}
.PartialTitle {
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.FeatureBox {
	display: flex;
	flex-direction: row;
	gap: 25px;
	flex-wrap: wrap;
	margin-top: 20px;
}
.CardFeature {
	width: calc(33.33% - 18px);
	height: 140px;
	padding: 12px 16px 16px 16px;
	border-radius: 10px;
	gap: 12px;
	background: #ffffff;
	box-shadow: 0px 0px 8px 0px #0000001a;
	cursor: pointer;
	position: relative;
}
.CardFeatureTop {
	display: flex;
	flex-direction: row;
}
.CardIcon {
	width: 24px;
	height: 24px;
	color: #ca0c64;
}
.CardOpenIcon {
	color: #e0e0e0 !important;
	margin-left: auto;
}
.CardFeatureBottom {
	gap: 5px;
}
.CardFeatureTitle {
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.CardFeatureDesc {
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #8888;
}
.Disabled {
	opacity: 50%;
	cursor: not-allowed;
}
.Badge {
	position: absolute;
	right: -5px;
	top: 10px;
	width: 60px;
	height: 23px;
	padding: 4px 8px;
	border-radius: 4px;
	gap: 12px;
	background: linear-gradient(180deg, #dd0064 0%, #ff97cc 100%);
	/* linear-gradient(180deg, color(display-p3 0.792 0.047 0.392) 0%, color(display-p3 1.000 0.616 0.792) 100%); */
	color: #fff;
	font-family: Inter;
	font-size: 12px;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	align-items: center;
}
.PublishBanner {
	margin-bottom: 10px;
	margin-top: 20px;
}
.PublishBanner svg {
	margin-left: auto;
}
.Split {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 15px;
}
.BasicCardInfo {
	padding: 18px 24px 18px 24px;
	border-radius: 12px;
	gap: 24px;
	background: #fff;
	border: 1px solid #f0f0f0;
	box-shadow: 0px 0px 8px 0px #0000001a;
}
.CardInfoTitle1 {
	font-family: Inter;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
}
.CardInfoContent {
	font-family: Inter;
	font-size: 32px;
	font-weight: 700;
	line-height: 39px;
	letter-spacing: 0em;
	text-align: left;
	color: #1b1b1b;
	gap: 10px;
}
.Price {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	gap: 5px;
}
.CardInfoSubtitle1 {
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
}
.CardInfoTitle2 {
	font-family: Inter;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #1b1b1b;
}
.GraphNav {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.GraphNav div {
	margin: 0;
}
.Currency1 {
	font-family: Inter;
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
}
.GraphBox canvas {
	width: 100% !important;
	height: unset !important;
	aspect-ratio: 2;
}
.BasicTable {
	overflow-x: auto;
}
.BasicTable td {
	white-space: nowrap;
	/* display: inline; */
}
.BasicTable thead {
	background-color: #e4e4e4;
	color: #838383;
}
.Currency2 {
	font-size: 14px;
	font-family: Inter;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
}
.SearchBox {
	width: calc(100% - 175px);
}
.DownloadBox div,
.DownloadBox label {
	cursor: pointer;
}
.PTable td {
	padding-top: 20px;
	padding-bottom: 20px;
}
.QRBox {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}
.PreviewQR {
	padding: 16px;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	/* aspect-ratio: 210 / 297;
	height: 100%; */
	width: 793.7007874px;
	height: 1122.519685px;
	display: flex;
	flex-direction: column;
}
.PreviewQRContent {
	padding: 20px;
	width: 100%;
	/* background: linear-gradient(317deg, #ffc7de61, transparent); */
}
.PreviewTitle {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 50px;
	text-align: center;
}
.Sponsor {
	display: flex;
	flex-direction: row;
	gap: 15px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
}
.Sponsor img {
	width: 120px;
	margin-right: auto;
}
.Sponsor span {
	margin-left: auto;
}
.Instruction {
	margin-top: 30px;
}
.DangerZone {
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.202);
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.DangerZoneTitle {
	margin-right: auto;
}
.CopyBox {
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin-left: -10px;
	gap: 20px;
}
.CopyBox p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
}
.InputGroup {
	gap: 10px;
	width: 100%;
	text-align: left;
	margin-bottom: 20px;
}
.InputGroup label {
	margin-left: unset;
	font-size: 14px;
	font-weight: bold;
}
.InputGroup img {
	width: 75%;
	border-radius: 12px;
}
.EditorContent {
	/* padding: 30px 100px; */
	max-width: 900px;
	/* width: 100%; */
	margin-left: auto;
	margin-right: auto;
}



.BoxAddress{
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-top: 12px;
	color: #888;
}
.BoxAddress svg{
	margin-top: auto;
	margin-bottom: auto;
	font-size: 25px;
	color: #888;
	flex: 0 0 16px;
}
.BoxAddress p{
	margin-top: auto;
	margin-bottom: auto;
}

.Address {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
	margin-bottom: 32px;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.Address *{
	color: #888 !important;
	text-decoration: none !important;
}
.BoxTime {
	gap: 12px;
	margin-top: 12px;
	width: fit-content;
}
.Time {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #888;
	display: flex;
	flex-direction: row;
	grid-area: 10px;
	width: 100%;
}
.Time .Clock {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.Time .Date{
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-right: 10px;
}
.Time .Clock svg, .Time .Date svg{
	flex: 0 0 16px;
	margin-top: auto;
	margin-bottom: auto;
}
.Time .Date div{
	margin-top: auto;
	margin-bottom: auto;
	flex: 0 0 calc(100% - 16px);
}
.Time .Clock div{
	margin-top: auto;
	margin-bottom: auto;
}

.ContainerBase{
	container-type: inline-size;
}

.Wrap{
	display: flex;
	flex-direction: row;
	gap: 10px;
	padding: 10px;
	box-sizing: border-box;
}

.SplitChild > button:first-child{
	margin-left: auto;
}
.SplitChild > button{
	width: unset;
}

.BasicTable2 td{
	white-space: unset;
	overflow-wrap: anywhere;
}

@container (max-width: 870px){
	.Wrap{
		flex-direction: column !important;
	}
	.SplitChild{
		width: 100%;
	}
	.SplitChild > div{
		width: calc(50% - 10px);
	}
}

@container (max-width: 906px){
	.BasicTable2 td{
		white-space: none;
		overflow-wrap: unset;
	}
}


@media (max-width: 1123px) {
	.TopInfo {
		flex-direction: column;
	}
	.LeftInfo,
	.RightInfo {
		width: 100%;
	}
}
@media (max-width: 1023px) {
	/* .SearchBox {
		width: 100%;
	}
	.SelectBox {
		width: calc(50% - 9px);
		margin-right: auto;
	}
	.DownloadBox {
		width: calc(50% - 9px);
	} */
}
@media (max-width: 992px) {
	.EditorContent {
		margin-top: -30px;
		padding: 30px 40px;
	}
	.TopInfo {
		flex-direction: row;
	}
	.LeftInfo,
	.RightInfo {
		width: 50%;
	}
	/* .SearchBox {
		width: calc(100% - 408px);
	}
	.SelectBox {
		width: unset;
		margin-right: unset;
	}
	.DownloadBox {
		width: unset;
	} */
}
@media (max-width: 772px) {
	.EditorContent {
		padding: 30px;
	}
	.TopInfo {
		flex-direction: column;
	}
	.LeftInfo,
	.RightInfo {
		width: 100%;
	}
	.Split2{
		flex-direction: row;
	}
	.Split2 .BasicCardInfo{
		width: calc(50% - 10px) !important;
	}
	/* .SearchBox {
		width: 100%;
	}
	.SelectBox {
		width: calc(50% - 9px);
		margin-right: auto;
	}
	.DownloadBox {
		width: calc(50% - 9px);
	} */
}
@media (max-width: 675px) {
	.FeatureBox {
		flex-direction: column;
	}
	.CardFeature {
		width: 100%;
	}
	.Link{
		display: none;
	}
	.LinkWrap{
		display: unset;
	}
	.Banner{
		margin-left: -20px;
    margin-right: -20px;
    border-radius: 0px;
		width: unset;
	}
	.Banner img{
		border-radius: 0px;
	}
	.RightInfo{
		margin-left: -20px;
    margin-right: -20px;
    width: unset;
    box-shadow: unset;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 24px;
    border-bottom: 10px solid #f7f7f7;
    border-radius: 0px;
	}
}

@media (max-width: 400px){
	.Split2{
		flex-direction: row;
	}

	.Split2 .BasicCardInfo{
		width: 100% !important;
	}
}