.NavigationPanel {
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 16.94px;
	/* margin-left: 18px; */
	/* margin-right: 18px; */
  margin-bottom: 30px;
}
h3{
  margin-bottom: 20px;
}
.NavItemSecondary {
	color: #888888 !important;
}
.NavItemSecondary a {
	color: #888888 !important;
	text-decoration: none;
}
.NavItemPrimary {
	color: #000 !important;
}
.NavItemPrimary a {
	color: #000 !important;
	text-decoration: none;
}
.Content{
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.BlankState{
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #ca0c64;
  text-align: center;
}
.Section{
  padding: 50px 115px;
}

@media (min-width: 1366px) {
  .Section {
      padding: 50px calc(150px + (100% - 1366px) / 2);
  }
}

@media (max-width: 992px) {
	.Section {
		padding: 20px;
	}
}