.InputForm {
	border-radius: 8px;
	background: var(--Background, #fff);
	background: var(--Background, color(display-p3 1 1 1));
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 4px 0px color(display-p3 0 0 0 / 0.1);
	padding: 12px;
	margin-bottom: 16px;
	border: 1px solid #eae7e7;
}

.InputForm[type="text"]:focus,
.InputForm[type="number"]:focus,
.InputForm[type="password"]:focus,
.InputForm[type="email"]:focus {
	outline-color: #fecadf;
	box-shadow: #f59abf;
	border: 1px solid #fae1eb;
}

input[type="text" i]:focus,
input[type="number" i]:focus,
input[type="password" i]:focus,
.InputForm[type="email"]:focus {
	outline-color: #fecadf;
	box-shadow: #f59abf;
	border: 1px solid #fae1eb;
}

.HidePassword {
	/* margin-top: -48px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: 5px;
	width: unset;
	box-shadow: none;
	padding-left: 5px;
	padding-right: 2px; */
	width: unset;
	box-shadow: none;
	padding-left: 5px;
	padding-right: 2px;
	position: absolute;
	right: 8px;
}

.InputPassword {
	padding-right: 29px !important;
}