.EditorContent {
	padding: 30px 50px;
	max-width: 1150px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 772px) {
	.EditorContent {
		padding: 30px;
	}
}
@media (max-width: 992px) {
	.EditorContent {
		margin-top: -30px;
		padding: 30px 40px;
	}
}
