.DeleteCard{
    width: 100%;
    background-color: white !important;
    color: red !important;
    border-color: red !important;
}
.DeleteCard:hover{
    background-color: red !important;
    color: white !important;
}
.CardPlus{
    margin-top: 60px;
    height: 198px;
    background-color: #fef6f9;
    box-shadow: none;
    border: 1px dashed #CA0C64;
    cursor: pointer;
}
.CardPlus:hover{
    background-color: #fcd8e6;
}
.ContentCardPlus{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #CA0C64;
}
.CardPlusIcon{
    margin-top: auto;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    height: 35px;
}
.CardPlusText{
    margin-bottom: auto;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    text-align: center;
}
.BlankData{
    display: flex;
    align-content: center;
    width: 100%;
    margin-top: 20px;
}
.FormControl{
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: center;
    gap: 20px;
}
.PopUpText{
    text-align: center;
    margin-bottom: 10px;
}
.IconPopUp{
    width: 100%;
    height: 100px;
    margin-bottom: 16px;
}
.IconPopUp svg{
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 16px;
}
.InputForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 16px;
    gap: 20px;
}

@media (max-width: 744px){
    .BlankData{
        flex-direction: row;
        flex-wrap: wrap;
    }
}