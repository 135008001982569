.JumboTop {
	background: url("../../../public/images/JumboBackground.png") no-repeat center
		center;
	background-size: cover;
	width: 100%;
	aspect-ratio: 5/1.7;
	align-items: center;
	justify-content: center;
	padding: 80px 90px;
	padding-bottom: 0;
	color: #fff;
	position: relative;
}
.JumboTitle {
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 30px;
	font-weight: 800;
	gap: 20px;
}
.JumboTitleSpecial {
	color: #dd0064;
	-webkit-text-stroke: 2px #fff;
}
.JumboDescription {
	font-size: 18px;
	text-align: center;
	line-height: 36px;
	margin-top: 40px;
}
.JumboChip {
	background-color: rgba(255, 255, 255, 0.15);
	color: #fff;
	padding: 10px 15px;
	border-radius: 999px;
	position: absolute;
	font-size: 15px;
	font-weight: 600;
}
.JumboOnline {
	top: 14%;
	right: 27%;
	transform: rotateZ(-20deg);
}
.JumboOnsite {
	top: 22%;
	right: 20%;
	transform: rotateZ(20deg);
}
.JumboHybrid {
	top: 34%;
	right: 24%;
	transform: rotateZ(-15deg);
}
.JumboButtonArea {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin-top: 40px;
}
.JumboButton {
	padding: 12px 20px;
	background-color: #fff;
	color: #121212;
	border-radius: 6px;
	border: none;
	align-items: center;
	flex-direction: row;
	gap: 10px;
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 800;
}

.CategoryArea,
.CategoryItem {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.CategoryItem {
	cursor: pointer;
}
.CategoryArea {
	flex-wrap: wrap;
	gap: 20px;
	max-height: 167px;
	min-height: 77px;
	overflow: hidden;
}
.CategoryItem {
	box-shadow: 1px 1px 5px 1px #ddd;
	padding: 10px;
	border-radius: 8px;
	gap: 10px;
	/* flex-basis: 23%; */
	/* max-width: 23%; */
	/* flex-grow: 1; */
	display: flex;
}
.CategoryName {
	font-size: 20px;
	color: #2c2c2c;
	font-weight: 700;
}
.CategoryEvents {
	font-size: 12px;
	color: #a7a7a7;
	margin-top: 5px;
}

.ScrollContainer {
	display: flex;
	white-space: nowrap;
	overflow-x: scroll;
	padding-bottom: 10px;
}
.DesktopEOL {
	display: block;
}
.EventCard {
	width: unset !important;
	flex-basis: 31.8% !important;
}
.ButtonBasic {
	width: unset;
	display: flex;
	padding: 10px 15px;
}

.ButtonBasic svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;
}

.ButtonBasic div {
	/* margin-top: -2.5px; */
	margin: 0;
}
.CustomSpotlight {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}
.CustomSpotBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-size: cover;
	background-position: center;
}
.CustomSpotText {
	width: 45%;
	height: 100%;
	padding-top: 92px;
	padding-bottom: 92px;
	padding-left: 90px;
}

.CustomSpotTitle {
	width: 100%;
	color: #fff;
	font-family: Inter;
	font-size: 35px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 16px;
}
.CustomSpotSubtitle {
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	color: #fff;
	margin-bottom: 16px;
}
.CustomSpotEvents {
	width: 45%;
	height: 100%;
	padding-top: 92px;
	padding-bottom: 92px;
	margin-left: auto;
	padding-left: 40px;
}
.CustomSpotlight .ButtonBasic {
	width: 150px;
}
.CustomNavSlideSpot {
	/* padding: 0 !important; */
	/* padding-left: 40px !important; */
	/* width: 45% !important; */
	right: 0;
	left: unset !important;
}
.SelectedEvent {
	width: 100%;
	display: flex;
	text-align: center;
}
.SelectedEvent .ButtonBasic {
	width: 150px;
	margin: auto;
	margin-top: 24px !important;
	text-align: center;
}
.SelectedEvent .ButtonBasic div {
	width: 100%;
	text-align: center;
}
.FrontBanner {
	display: flex;
	padding: 0;
	overflow: hidden;
}
.FrontBannerInner {
	list-style: none;
	min-width: 100%;
}
.FrontBannerInner div {
	padding: 0;
	margin: 0;
	list-style: none;
	display: block;
}
.FrontBanner img {
	width: 100%;
	height: 100px;
	object-fit: cover;
	object-position: center;
	border: 0;
}
.CustomNavCarousel {
	height: 160px !important;
}
.AbsoluteBg {
	position: absolute;
	height: 500px;
	z-index: -1;
}
.JumboSec {
	display: flex;
	width: 100%;
	flex-direction: row;
	color: white;
	margin-top: 100px;
}
.JumboSecL {
	padding-left: 90px;;
	padding-right: 10px;
	width: 50%;
	margin-bottom: 80px;
}
.JumboSecR {
	padding-left: 50px;
	width: 50%;
	overflow: hidden;
	display: flex;
}
.JumboSecL div {
	text-align: left !important;
	margin-bottom: 16px;
	margin-top: 0px;
}
.JumboSecL button {
	margin-top: unset;
}
.JumboSecR img {
	width: 730px;
	margin-top: 40px;
	margin-left: auto;
}
.Col2 {
	width: 50%;
	margin-top: auto;
	margin-bottom: auto;
	color: black;
	display: flex;
}
.Col2 div {
	text-align: left !important;
	margin-bottom: 16px;
	margin-top: 0px;
}
.Col2 button {
	margin-top: unset;
}
.FrontCover2 {
	width: 100%;
	border-radius: 7px;
	background: lightgray 50% / cover no-repeat;
	box-shadow: 0px 53px 93px 0px rgba(95, 7, 50, 0.17);
}
.SliderContainer{
	position: relative;
	margin-top: 20px;
	width: 100%;
}
.SlideBox{
	display: flex;
	flex-direction: row;
	gap: 10px;
	overflow: auto;
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
	padding-bottom: 10px;
	
}
.TopicInfo{
	width: 100%;
}
.TopicName{
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	width: 100%;
}
.ArrowTopic{
	position: absolute;
	padding: 8px;
	border-radius: 6px;
	background-color: #000;
	opacity: 0%;
	cursor: pointer;
}
.ArrowTopic svg{
	font-weight: 600;
	font-size: 25px;
	color: #fff;
}
.SliderContainer:hover .ArrowTopic{
	opacity: 50%;
}
.ArrowTopicRight{
	right: 0;
}
.ArrowTopicLeft{
	left: 0;
}
.JumboButtonAreaMobile{
	display: none;
	flex-direction: column;
}
.SearchForm{
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: calc(100% - 20px);
	background-color: #fff;
	border-radius: 8px;
	padding: 5px 10px 5px 10px;
}
.SearchInput{
	border: none !important;
	box-shadow: 0 0 0 #fff !important;
	height: 40px;
	outline: none;
}
.SearchInput:focus{
	border: none !important;
	box-shadow: 0 0 0 #fff !important;
}
.SearchInput::placeholder{
	color: #9f9f9f;
}
.SearchForm svg{
	margin-top: auto;
	margin-bottom: auto;
}
.JumboButtonAreaMobile .JumboButton{
	flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  font-size: 14px;
	height: 48px;
}

@media (min-width: 1366px) {
	.JumboTop{
		padding-bottom: 0;
		padding-top: 30px;
		padding-left: calc(100px + (100% - 1366px) / 2);
		padding-right: calc(100px + (100% - 1366px) / 2);
		aspect-ratio: 5 / 1.3;
	}
	.JumboTitle{
		font-size: 37px;
	}
	.CustomSpotTitle{
		font-size: 35px;
	}
	section h3{
		font-size: 20px;
	}
	.CustomSpotText, .JumboSecL {
		padding-left: calc(100px + (100% - 1366px) / 2);
}
}

@media (max-width: 1130px){
	.JumboTop{
		padding-bottom: 80px;
	}
}

@media (max-width: 992px) {
	.JumboTop{
		padding-bottom: 80px;
		padding-left: 40px;
		padding-right: 40px;
	}
	.CustomSpotBox {
		display: flex;
		flex-direction: column;
		gap: 20;
	}
	.JumboButtonArea{
		display: none;
	}
	.JumboButtonAreaMobile{
		display: flex;
	}
	.CustomSpotText {
		width: 100%;
		padding-bottom: 10px;
		padding-top: 30px;
		padding-left: 40px;
		padding-right: 40px;
	}
	.CustomSpotEvents {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 20px;
	}
	.CustomNavSlideSpot {
		width: 100% !important;
	}
	.CustomNavCarousel {
		padding: 20px !important;
	}
	.AbsoluteBg {
		height: 645px;
	}
	.JumboSec {
		flex-direction: column;
	}
	.JumboSecL {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}
	.JumboSecR {
		width: 100%;
	}
	.JumboSecR img {
		width: 530px;
		margin-top: unset;
	}
	.Col2 {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}
	/* .CategoryItem {
		flex-basis: 31.33%;
		max-width: 31.33%;
	} */
}

@media (max-width: 772px) {
	.CategoryArea{
		max-height: 115px;
		min-height: 50px;
	}
	.CustomNavCarousel {
		height: 100px !important;
	}
	/* .CategoryItem {
		flex-basis: 48%;
		max-width: 48%;
		=====================
		flex-basis: unset;
		max-width: unset;
		flex-grow: unset;
		width: unset;
	} */
	.CategoryItem img{
		width: 25px;
		height: 25px;
	}
	.CategoryName{
		font-size: 14px;
		font-weight: 600;
	}
	section h3{
		font-size: 18px;
	}
	.JumboTitle {
		font-size: 19px;
		gap: 10px;
		flex-direction: row;
		text-align: center;
	}
.JumboDescription {
		font-size: 14px;
		line-height: 28px;
	}
	.CustomSpotTitle{
		font-size: 19px;
	}
	.CustomSpotSubtitle{
		font-size: 15px;
	}
}

@media (max-width: 570px) {
	.DesktopEOL {
		display: none;
	}
	.JumboTop {
		padding: 100px 20px;
		aspect-ratio: unset;
	}
	
	.JumboChip {
		font-size: 12px;
		opacity: 0.7;
		/* display: none; */
	}
	
	.JumboOnline {
		top: 10%;
		right: 28%;
	}
	.JumboOnsite {
		top: 12%;
		right: 8%;
	}
	.JumboHybrid {
		top: 27%;
		right: 6%;
	}
	.JumboButtonArea {
		flex-direction: column;
		/* width: 100%; */
	}
	.JumboButton {
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		padding: 16px 30px;
		font-size: 14px;
	}
	.CustomSpotTitle {
		font-size: 19px;
	}
	.CustomSpotSubtitle {
		font-size: 14px;
	}
	/* .CategoryItem {
		flex-basis: 98%;
		max-width: 98%;
	} */
	.ArrowTopic{
		display: none;
	}
}
