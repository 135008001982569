.CategoryArea{
  min-height: unset !important;
  max-height: unset !important;
  padding: 20px;
}
h3{
  padding-left: 20px;
  padding-right: 20px;
}
.CategoryItem{
  flex-basis: 23%;
}

@media (max-width: 772px){
  .CategoryItem{
    flex-basis: auto;
  }
}