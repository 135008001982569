h5 {
  font-size: 14px;
}
h3{
  padding: 0;
  margin-top: 20px;
  font-size: 16px;
}
h3 > *{
  font-size: 16px;
}
h2{
  margin-bottom: 20px;
  font-size: 20px;
}
h2 > *{
  font-size: 20px;
}
ul{
  margin-left: 20px;
  list-style: square;
}
ol{
  margin-left: 30px;
}
a{
  text-decoration: none;
}
.NavigationPanel {
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 16.94px;
	/* margin-left: 18px; */
	/* margin-right: 18px; */
  margin-bottom: 30px;
}
h3{
  margin-bottom: 20px;
}
.NavItemSecondary {
	color: #888888 !important;
}
.NavItemSecondary a {
	color: #888888 !important;
	text-decoration: none;
}
.NavItemPrimary {
	color: #000 !important;
}
.NavItemPrimary a {
	color: #000 !important;
	text-decoration: none;
}
.MainBox{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.Sidebar{
  width: 150px;
  height: auto;
}
.Menu{
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 90px;
}
.MenuItem{
  padding: 10px;
  text-align: left;
  font-size: 14px;
  text-decoration: none;
  color: #888;
}
.MenuItemActive{
  font-weight: 600;
  color: #000;
  border-left: 2px solid #ca0c64;
}
.MainContent{
  width: calc(100% - 170px);
}
.Header{
  margin-bottom: 30px;
}
.MenuCollpase{
  display: none;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  background-color: #fff;
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: auto; 
}
.MenuCollpase::-webkit-scrollbar {
  display: none;
}
.MenuCollpase a{
  white-space: nowrap;
}
.MainBox div{
  font-size: 14px;
}
/* div{
  font-size: 14px;
} */
 .TableBox, .TableBox tr, .TableBox td{
  border: 1px solid
 }
 .TableBox h3{
  margin: 0;
 }
 .TableBox .SubTitle1{
  color: blue;
 }
 .TableBox .SubTitle2{
  color: rgb(255, 183, 0);
 }
 .TableBox .Content{
  text-align: center;
 }
 .TableBox .ContentTitle{
  font-weight: 600;
 }
 .TableBox .ContentMain{
  font-size: 35px;
 }
 .TableBox .ContentDesc{
  font-weight: 600;
 }
 

@media(max-width: 560px){
  .Sidebar{
    display: none;
  }
  .MainContent{
    width: 100%;
  }
  .Header{
    margin-bottom: 0px;
  }
  .MenuCollpase{
    display: flex;
    position: sticky;
    top: 70px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .MenuItemActive{
    border-left: none;
    border-bottom: 2px solid #ca0c64;
  }
}