.Header {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	height: 90px;
	display: flex;
	flex-direction: row;
	gap: 40px;
	align-items: center;
	padding: 0px 40px;
	z-index: 5;
}

.Logo {
	height: 40px;
	aspect-ratio: 2061/421;
}

.SearchForm {
	border: 1px solid #ddd;
	border-radius: 8px;
	flex-basis: 25%;
	display: flex;
	gap: 20px;
	height: 50px;
	align-items: center;
	padding: 0px 20px;
}

.SearchForm svg {
	flex-grow: 0;
	flex-shrink: 0;
}

.SearchInput {
	display: flex;
	flex-grow: 1;
	height: 48px;
	border: none;
	outline: none;
	font-size: 15px;
	width: 100%;
}

.SearchInput:focus {
	border: none !important;
	box-shadow: 0 0 0 #fff !important;
}

.Menu {
	display: flex;
	gap: 20px;
	flex-grow: 1;
	justify-content: flex-end;
}

.MenuItem {
	color: #555;
	text-decoration: none;
	font-size: 14px;
	font-weight: 700;
	color: #1b1b1b;
	display: flex;
	gap: 10px;
	height: 50px;
	padding: 0px 10px;
	align-items: center;
}

.MenuItem:hover {
	border: 1px solid #ca064c;
	border-radius: 8px;
}

.HeaderMobile {
	display: none;
}

.Logo {
	height: 40px;
	width: min-content;
}

.Toggler {
	border: 1px solid #ddd;
	height: 40px;
	aspect-ratio: 1;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.MenuMobile {
	display: none;
}

.ProfileIcon {
	height: 50px;
	aspect-ratio: 1;
	/* background-color: #ddd; */
	border-radius: 8px;
	cursor: pointer;
	background-size: cover;
}

.ProfileMenu {
	background-color: #fff;
	border-radius: 12px;
	padding: 15px;
	position: fixed;
	top: 70px;
	right: 20px;
	width: 255px;
	z-index: 10;
	box-shadow: 1px 1px 5px 1px #ddd;
}

.ProfileMenuItem {
	text-decoration: none;
	color: #333;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	gap: 10px;
	font-size: 14px;
	padding: 0px 15px;
	font-weight: 600;
	border-radius: 8px;
}

.ProfileMenuItem:hover {
	background-color: #f9f9f9;
	border-radius: 8px;
}

.ProfileMenuItemActive {
	background-color: #eee !important;
}

.Separator {
	width: 60%;
	height: 1px;
	background-color: #ddd;
	margin: 10px 20%;
}

@media (max-width: 992px) {

	/* .Header {
        padding: 0px 20px;
        gap: 20px;
        height: 70px;
    } */
	/* .Menu,.SearchForm {
        display: none;
    } */
	.Header {
		display: none;
	}

	.ProfileMenu {
		display: none;
	}

	.ProfileMenuMobile {
		display: unset;
	}

	.HeaderMobile {
		display: flex;
		flex-direction: row;
		padding: 0px 20px;
		height: 70px;
		align-items: center;
		border-bottom: 1px solid #ddd;
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		z-index: 5;
		background-color: #fff;
	}

	.LogoArea {
		width: auto;
		display: flex;
		flex-grow: 1;
	}

	.Logo {
		width: min-content;
		height: 40px;
	}

	.Toggler {
		border: 1px solid #ddd;
		height: 40px;
		aspect-ratio: 1;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		margin-left: auto;
	}

	.MenuMobile {
		display: flex;
		position: fixed;
		top: 71px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		left: 100%;
		background-color: #fff;
		padding: 20px;
		flex-direction: column;
		gap: 10px;
		transition: 0.3s;
		z-index: 5;
	}

	.MenuMobileActive {
		left: 0%;
	}

	.MenuMobileItem {
		width: 100%;
		padding: 15px 20px;
		border-radius: 8px;
		box-sizing: border-box;
		color: #212121;
		text-decoration: none;
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.ProfileMenu {
		position: static;
		width: 100%;
		margin-top: 20px;
		box-shadow: none;
		padding: 0px;
	}

	.ButtonCollapse {
		width: 100%;
		margin-top: 10px;
	}
}