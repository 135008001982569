.Subtitle {
	font-family: Inter;
	font-size: 18px;
	font-weight: 700;
	color: #2c2c2c;
	margin-top: 34.5px;
	margin-bottom: 26.5px;
	margin-left: 10px;
}
.TicketGroup {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 25px;
}
.TicketBox {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-basis: 31%;
  min-width: 300px;
}
.TicketCard {
	border-radius: 16px 16px 0px 0px;
	box-shadow: 0 0 18px #eaeaea;
	width: 100%;
	max-width: 434.83px;
	background-color: #fff;
	position: relative;
}
.Badge{
	position: absolute;
	right: -5px;
	top: -10px;
	padding: 5px 10px;
	border-radius: 20px;
	background-color: yellow;
	font-weight: 600;
	text-align: center;
	font-size: 13px;
}
.TicketDivider {
	position: relative;
	padding-top: 11.5px;
	padding-bottom: 11.5px;
}
.Dashed {
	border: 1px dashed #ddd;
}
.HalfCircleLeft,
.HalfCircleRight {
	width: 10.815px;
	height: 21.63px;
	background-color: white;
	box-shadow: inset 0 20px 0 #eaeaea7a;
	position: absolute;
	top: 0;
}
.HalfCircleLeft {
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
	left: 0;
}
.HalfCircleRight {
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
	right: 0;
}
.TicketTop {
	width: 100%;
	/* height: 108px; */
	padding: 0px 24px 12px 20px;
	gap: 10px;
}
.CoverBox {
	width: 30%;
	display: flex;
	align-items: center;
	padding: 16px 0px 12px 20px;
}
.CoverBox img {
	aspect-ratio: 5/3;
	width: 100%;
	margin: auto;
	border-radius: 4px;
	object-fit: cover;
}
.TicketSplit {
	display: flex;
	flex-direction: row;
}
.TicketBottom {
	width: 100%;
	padding: 12px 24px 20px 20px;
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 15px;
}
.TicketTitle {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.TicketPrice {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.TicketDesc {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline;
}
.TicketTime {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	color: #000;
}
.ActionTicket {
	color: #ca0c64;
	border: 1px solid #ca0c64;
	border-radius: 50%;
	height: 24px;
	width: 24px;
	/* padding: 1px; */
	display: flex;
	cursor: pointer;
}
.ActionTicket svg {
	margin: auto;
	width: 90%;
	height: 90%;
}
.TicketCount {
	font-weight: 500;
}
.TextSecondary {
	color: #888888;
}
.TextPrimary {
	color: #ca0c64;
}
.TicketBasic {
	color: #000;
}
.Blank {
	margin-top: 150px;
	gap: 20px;
}
.Blank img {
	margin-left: auto;
	margin-right: auto;
	width: 200px;
	height: 200px;
}
.BlankDesc {
	text-align: center;
	font-weight: 400;
	font-size: 18px;
}
.DecorationBox {
	position: absolute;
	top: -364.94px;
	right: 0;
	z-index: -1;
	overflow: hidden;
}
.Decoration {
	width: 837.9px;
	height: 837.9px;
	/* top: -364.94px; */
	/* left: 541.93px; */
	transform: translate(364.94px, 54px);
	background: radial-gradient(
			43.61% 43.61% at 50% 50%,
			rgba(221, 0, 99, 0.06) 0%,
			rgba(221, 0, 100, 0) 100%
		)
		/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	/* radial-gradient(43.61% 43.61% at 50% 50%, color(display-p3 0.792 0.047 0.392 / 0.1) 0%, color(display-p3 0.792 0.047 0.392 / 0) 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
}
.NavShowItemBox{
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.NavShowItem{
	margin-left: auto;
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-size: 14px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: pointer;
	font-weight: 600;
}
.NavShowItem svg{
	margin-top: auto;
	margin-bottom: auto;
	width: 20px;
	height: 20px;
}
.NewTicketPopUp{
	display: flex;
	flex-direction: column;
	gap: 10px;
}.NewTicketPopUp svg{
	margin-left: auto;
	margin-right: auto;
	width: 50px;
	height: 50px;
	color: rgb(145, 205, 255);
}
.NewTicketPopUp span, .NewTicketPopUp button{
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.NewTicketPopUp button{
	margin-top: 10px;
}