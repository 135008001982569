.MainSliderBox{
    position: relative;
    width: 100%;
}
.SliderBox{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    position: relative;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none; /* for Firefox */
}
.Content{
    display: flex;
    white-space: nowrap;
    /* overflow-x: scroll; */
    padding-bottom: 10px;
    z-index: 888;
    transition-duration: 300ms;
}
.SliderBox::-webkit-scrollbar {
    display: none;
  }
.Navigator{
    display: none;
    flex-direction: row;
    position: absolute;
    /* margin-top: 20px; */
    left: 0;
    width: 100%;
    height: 100%;
    /* padding: 50px; */
}
.MainSliderBox:hover{
    .Navigator {
        display: flex;
    }
}
.ButtonNav{
    margin-top: auto;
    margin-bottom: auto;
    color: rgb(255, 255, 255);
    background-color: black;
    opacity: 0.3;
    z-index: 999;
    width: 50px;
    height: 50px;
    font-size: 30px;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
}

.ButtonNav:hover{
    opacity: 0.9;
    border: 1px solid #fff;
}

.ButtonNavLeft{
    margin-right: auto;
    margin-left: 0px;
    z-index: 999;
}

.ButtonNavRight{
    margin-left: auto;
    margin-right: 0px;
    z-index: 999;
}

.ButtonNavLeft svg{
    transform: rotate(0deg);
}

.ButtonNavRight svg{
    transform: rotate(180deg);
}

@media (max-width: 992px){
    .Navigator{
        /* padding: 20px; */
    }
}