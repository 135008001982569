.CarouselBox {
	width: 100%;
	overflow: hidden;
}
.Carousel {
	display: flex;
	padding: 0;
	overflow: visible;
	transition-duration: 500ms;
	z-index: 999;
}
.CarouselInner {
	list-style: none;
	min-width: 100%;
}
.CarouselInner div {
	padding: 0;
	margin: 0;
	list-style: none;
	display: block;
}
.Carousel img {
	width: 100%;
	height: 130px;
	object-fit: cover;
	object-position: center;
	border: 0;
	border-radius: 10px;
	cursor: pointer;
}
.Carousel .Skeleton {
	width: 100%;
	height: 160px;
	object-fit: cover;
	object-position: center;
	border: 0;
	border-radius: 10px;
}
.Navigator {
	display: flex;
	flex-direction: row;
	position: absolute;
	/* margin-top: 20px; */
	left: 0;
	width: 100%;
	height: 312px;
	padding: 50px 115px;
}

.ButtonNav {
	margin-top: auto;
	margin-bottom: auto;
	color: rgb(255, 255, 255);
	background-color: black;
	opacity: 0.3;
	z-index: 999;
	width: 50px;
	height: 50px;
	font-size: 30px;
	border-radius: 8px;
	padding: 10px;
}

.ButtonNav:hover {
	opacity: 0.9;
	border: 1px solid #fff;
}

.ButtonNavLeft {
	margin-right: auto;
	margin-left: 0px;
	z-index: 999;
}
.ButtonNavRight {
	margin-left: auto;
	margin-right: 0px;
	z-index: 999;
}

.ButtonNavLeft svg {
	transform: rotate(0deg);
}

.ButtonNavRight svg {
	transform: rotate(180deg);
}

@media (min-width: 1366px){
	.Navigator{
		padding: 50px calc(100px + (100% - 1366px) / 2);
	}
}

@media (max-width: 760px) {
	.Carousel img {
		height: 100px;
	}
}
