.SidebarExt {
	display: flex;
	flex-direction: row;
	width: 25%;
	max-width: 400px;
	min-width: 340px;
}
.MainMenu {
	padding: 16px 10px 16px 0px;
	width: 64px;
	border-right: 1px solid #eaeaea;
}
.ExtMenu {
	width: calc(100% - 44px);
	margin-top: 40px;
	padding: 16px 26px;
	border-right: 1px solid #eaeaea;
}
.SelectMenu {
	margin: 16px 0px;
}
.AddOrg {
	width: 100%;
	padding: 10px 10px;
	display: flex;
	cursor: pointer;
}
