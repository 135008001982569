body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.rmdp-calendar div {
	/* display: unset; */
	/* flex-direction: unset; */
}
.rmdp-top-class,
.rmdp-header,
.rmdp-header-values,
.rmdp-day-picker,
.rmdp-week,
.rmdp-ym {
	flex-direction: row;
}
.rmdp-header {
	margin: auto;
}
.rmdp-header div {
	flex-direction: row;
}

/* ADD TO CALENDAR BUTTON */
.atcb-initialized {
	width: 100%;
}
.atcb-button {
	width: 100%;
	background-color: white;
	border-color: black;
}
.pointer{
	cursor: pointer;
}
/* ---------------------= */

/* ------ LAYOUT PRINT PDF -------- */

.MainPaper {
	display: flex;
	flex-direction: column;
	gap: 18.897637795px;
	padding: 18.897637795px;
	margin: 37.795275591px;
	width: 756.7007874px;
	height: 1085.519685px;
	background-color: #ddd;
}
.MainPaper .GroupInner {
	gap: 18.897637795px;
	display: flex;
	flex-direction: column;
}
.PaperSplit {
	display: flex;
	flex-direction: row !important;
}
.BoxInner {
	background-color: #fff;
	padding: 18.897637795px;
	gap: 15px;
	font-size: 12px;
}
.BoxInner div {
	overflow: hidden;
	overflow-wrap: anywhere;
}
.LeftPanel {
	width: 434.004898px;
}
.ImgBanner {
	width: 100%;
}
.BoxInnerTitle {
	font-weight: bold;
	font-size: 16px;
}
.InfoGroup {
	gap: 10px;
	align-content: center;
	margin-top: auto;
	margin-bottom: auto;
}
.InfoGroup svg {
	margin-top: auto;
	margin-bottom: auto;
	width: 23px;
	height: 23px;
}
.InfoGroup div {
	margin-top: auto;
	margin-bottom: auto;
	width: calc(100% - 40px);
}
.GroupSocmed svg {
	width: 30px;
	height: 30px;
}

/* ------------------------- */
