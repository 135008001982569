.BillingTitle {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
}

.EventSelector {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 25px;
}

.TotalAmount {
    color: rgb(202, 12, 100);
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.DataTable {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.BlankDataTable {
    width: 100%;
    text-align: center;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 50px;
}

.BlankDataTable .Icon {
    height: 65px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.FormAddWd1 {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40%;
    margin-left: auto;
}

.FormWd1Content {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.PopupNotify {
    display: flex;
    text-align: center;
    font-size: 16px;
}

.IconPopUp {
    width: 100%;
    height: 100px;
}

.IconPopUp svg {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 16px;
}

.InfoBox {
    padding: 20px;
    border-radius: 10px;
    background-color: #eaeaea;
    margin-bottom: 20px;
    color: #232323;
    gap: 15px;
}

.AddListTbl {
    width: 100%;
    padding: 5px;
    border-radius: 8px;
    background-color: #f569aa53;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.IconAddListTbl {
    margin-right: 5px;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    width: 15px;
    height: 15px;
}

.TitleAddListTbl {
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 5px;
    font-size: 15px;
}

.InputForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 16px;
    gap: 20px;
}

.FormControl {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 992px) {
    .FormAddWd1 {
        margin-bottom: 250px;
    }
}

@media (max-width: 706px) {
    .FormAddWd1 {
        width: 100%;
    }
}