@keyframes loading {
	to {
		background-position-x: -20%;
	}
}

.Skeleton {
	width: 100px;
	height: 40px;
	background-color: rgb(202 202 202);
	background: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0) 40%,
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 60%
		)
		rgb(202 202 202);
	background-size: 200% 100%;
	background-position-x: 180%;
	animation: 1s loading ease-in-out infinite;
}
