.Card {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 350px;
}
.Circle {
    width: 10px;
    border-radius: 99px;
    background-color: #CA0C64;
    aspect-ratio: 1/1;
    margin-bottom: 15px;
}
.Title {
    font-size: 14px;
    font-weight: 700;
}
.Description {
    font-size: 12px;
    color: #777;
    margin-top: 10px;
    line-height: 20px;
}
.Button {
    font-size: 14px;
    background-color: #212121;
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 10px;
    font-weight: 700;
}