@keyframes hideRight {
	0% {
		translate: 0px 0px;
	}

	100% {
		translate: 1000px 0px;
	}
}

@keyframes showRight {
	0% {
		translate: 1000px 0px;
	}

	100% {
		translate: 0px 0px;
	}
}

@keyframes showTop {
	0% {
		translate: 0px 1000px;
	}

	100% {
		translate: 0px 0px;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.MainContainer {
	margin-top: 28px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
	max-width: 1208px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.NavPane {
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #888888;
	margin-bottom: 17px;
	cursor: pointer;
}

.MainNavPane {
	color: #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline;
	max-width: 230px;
}

.SplitPanel {
	display: flex;
	flex-direction: row;
	gap: 45px;
}

.LeftPanel {
	width: 55%;
}

.MainBanner {
	width: 100%;
	margin-bottom: 27px;
}

.MainBanner img {
	width: 100%;
	aspect-ratio: 5/3;
	border-radius: 12px;
	object-fit: cover;
}

.Navigation {
	display: flex;
	flex-direction: row;
	gap: 12px;
	margin-bottom: 29px;
	padding-bottom: 10px;
	padding-top: 10px;
	overflow: auto;
	position: sticky;
	top: 90px;
	background-color: #fff;
	z-index: 1;
}

.NavBtn {
	padding: 8px 12px 8px 12px;
	border-radius: 10px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #000;
	background-color: #f1f1f1;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.NavBtnActive {
	background-color: #ca0c6412;
	color: #ca0c64;
	border: 1px solid #ca0c64;
}

.GroupContent {
	gap: 20px;
	margin-bottom: 32px;
}

.GroupTitle {
	font-family: Inter;
	font-size: 20px;
	font-weight: 700;
}

.GroupContentData {
	font-size: 15px;
}

.GlobalSeatMap {
	width: 100%;
	margin-top: 20px;
}

.TicketBox {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.TicketCard {
	border-radius: 16px 16px 0px 0px;
	box-shadow: 0 0 18px #eaeaea;
	width: 100%;
	max-width: 434.83px;
}

.TicketDivider {
	position: relative;
	padding-top: 11.5px;
	padding-bottom: 11.5px;
}

.Dashed {
	border: 1px dashed #ddd;
}

.HalfCircleLeft,
.HalfCircleRight {
	width: 10.815px;
	height: 21.63px;
	background-color: white;
	box-shadow: inset 0 20px 0 #eaeaea7a;
	position: absolute;
	top: 0;
}

.HalfCircleLeft {
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
	left: 0;
}

.HalfCircleRight {
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
	right: 0;
}

.TicketTop {
	width: 100%;
	/* height: 108px; */
	padding: 16px 24px 12px 20px;
	gap: 10px;
}

.CoverBox {
	width: 85px;
	display: flex;
	align-items: center;
	padding: 16px 0px 12px 20px;
}

.CoverBox img {
	aspect-ratio: 1;
	width: 100%;
	margin: auto;
	border-radius: 12px;
	object-fit: cover;
}

.TicketSplit {
	display: flex;
	flex-direction: row;
}

.TicketBottom {
	width: 100%;
	padding: 12px 24px 20px 20px;
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.TicketTitle {
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.TicketPrice {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.TicketDesc {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	width: 100%;
	max-height: 17px;
	overflow: hidden;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline; */
}

.TicketTime {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.ActionTicket {
	color: #ca0c64;
	border: 1px solid #ca0c64;
	border-radius: 50%;
	height: 24px;
	width: 24px;
	/* padding: 1px; */
	display: flex;
	cursor: pointer;
}

.ActionTicket svg {
	margin: auto;
	width: 90%;
	height: 90%;
}

.TicketCount {
	font-weight: 500;
}

.TextSecondary {
	color: #888888;
}

.TextPrimary {
	color: #ca0c64;
}

.TicketBasic {
	color: #000;
}

.RightPanel {
	width: 41%;
}

.RightInfo {
	width: 100%;
	padding: 24px;
	padding-bottom: 16px;
	box-shadow: 0px 0px 8px 0px #0000001a;
	border-radius: 16px;
	background-color: #fff;
	margin-bottom: 24px;
}

.InfoTitle {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}

.Address {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #8b8b8b;
	margin-bottom: 32px;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.Address * {
	color: #888 !important;
	text-decoration: none !important;
}

.BoxTime {
	gap: 12px;
	margin-top: 12px;
	width: fit-content;
}

.Time {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #888;
	display: flex;
	flex-direction: row;
	grid-area: 10px;
	width: 100%;
}

.Time .Clock {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.Time .Date {
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-right: 10px;
}

.Time .Clock svg,
.Time .Date svg {
	flex: 0 0 16px;
	margin-top: auto;
	margin-bottom: auto;
}

.Time .Date div {
	margin-top: auto;
	margin-bottom: auto;
	flex: 0 0 calc(100% - 16px);
}

.Time .Clock div {
	margin-top: auto;
	margin-bottom: auto;
}

.FooterInfo {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-bottom: 0;
	align-items: center;
}

.Subtitle {
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #a7a7a7;
	flex-shrink: 0;
	flex-grow: 0;
}

.InfoOrg {
	display: flex;
	flex-direction: row;
	gap: 5px;
	/* margin-left: auto; */
	align-items: center;
	color: #8a8a8a;
	/* max-width: calc(100% - 90px); */
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;
}

.InfoOrg img {
	width: 32px;
	border-radius: 50%;
	aspect-ratio: 1;
}

.InfoOrg p {
	text-overflow: ellipsis;
	overflow: hidden;
	text-wrap: nowrap;
	max-width: calc(100% - 47px);
	font-size: 13px;
}

.CartBox {
	width: 100%;
	min-height: 556px;
	top: 430px;
	left: 740px;
	padding: 24px 24px 16px 24px;
	border-radius: 16px;
	gap: 32px;
	box-shadow: 0 0 8px #eaeaea;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.CartBoxFull {
	min-height: 56px;
}

.CartBoxTitle {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 12px;
	flex-direction: row;
	gap: 5px;
	width: 100%;
}

.CartBoxInner {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: auto;
}

.CartBlank {
	margin: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
}

.CartBlankFull {
	flex-direction: row;
	gap: 10px;
}

.CartBlank img {
	width: 206.17px;
	height: 206.17px;
	transform: rotate(-15deg);
}

.CartBlankFull img {
	height: 50px;
	width: 50px;
	margin: auto;
}

.CartBlank div {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
}

.CartBlankFull div {
	font-size: 16px;
}

.CoverBuyButton {
	margin-top: 20px;
	padding: 20px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 18px #eaeaea;
	position: sticky;
	top: 100px;
}

.CollapseInfo {
	display: none;
}

.CollapseWrapper {
	position: fixed;
	right: 0;
	background-color: #00000083;
	height: calc(100% - 70px);
	width: 100%;
	z-index: 99;
	top: 70px;
	display: none;
	animation: fade 300ms;
}

.CollapseSidebar {
	/* background-color: white; */
	height: 100%;
	width: 100%;
	max-width: 439px;
	margin-left: auto;
	/* padding: 40px; */
	overflow: auto;
	animation: showRight 500ms;
	display: flex;
	flex-direction: row;
}

.CollapseSidebarLeft {
	width: 20px;
	position: relative;
}

.CollapseSidebarRight {
	background-color: white;
	height: 100%;
	width: calc(100% - 20px);
	margin-left: auto;
	padding: 40px;
	overflow: auto;
	animation: showRight 300ms;
}

.CloseSidebar {
	position: absolute;
	left: 0;
	background-color: white;
	border-top-left-radius: 25%;
	border-bottom-left-radius: 25%;
	padding: 5px;
	top: 30px;
	height: 50px;
	width: 100%;
	display: flex;
	cursor: pointer;
}

.CloseSidebar svg {
	margin: auto;
}

.CloseSidebar .CartBox {
	animation: fade 500ms;
}

.FloatCartIconLink {
	text-decoration: none;
}

.FloatCartIcon {
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: 140px;
	height: 50px;
	background-color: #ca0c64;
	border-radius: 15px;
	border: none;
	color: #fff;
	display: none;
	cursor: pointer;
	z-index: 2;
	flex-direction: row;
	/* animation: hideRight 500ms; */
}

.FloatCartIconEmbed {
	bottom: 0;
	border-radius: 0;
	left: unset;
	right: unset;
	background-color: #fff;
	width: 100%;
	max-width: 1128px;
}

.FloatCartIconEmbedInner {
	width: 100%;
	flex-direction: row;
	padding-bottom: 10px;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 10px;
	background-color: #ca0c64;
	margin-top: 0 !important;
}

.FloatCartIcon svg {
	margin: auto 10px;
	width: 30px;
	height: 60%;
}

.FloatCartIconEmbed svg {
	margin-left: auto;
}

.FloatCartIcon div {
	margin-top: auto;
	margin-bottom: auto;
}

.FloatCartIconEmbed div {
	margin-right: auto;
}

.DateSelectorGroup {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.DateSelectorGroup button {
	margin-left: auto;
	margin-right: auto;
	width: 100% !important;
}

.DateSelector {
	margin-right: auto;
}

.DateSelector,
.RenderDates {
	display: flex;
	flex-direction: row;
	gap: 10px;
	padding: 16px 24px 12px 20px;
	align-items: center;
	width: 100%;
}

.DateSelector label {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.DateSelector svg {
	width: 25px;
	height: 25px;
	color: rgb(255, 170, 0);
}

.RenderDates {
	flex-direction: column;
	align-items: start;
	padding-top: 2px;
}

.DateSeatMap {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 5px;
	padding-top: 5px;
	align-items: center;
	/* border-bottom: 1px dashed #777575; */
}

.PopUpAlert {
	display: flex;
	flex-direction: column;
	gap: 30px;
	text-align: center;
	width: 100%;
}

.PopUpAlert svg {
	margin-left: auto;
	margin-right: auto;
	width: 65px;
	height: 65px;
}

.AlertContent {
	text-align: center;
}

.PopUpAlert button {
	margin-left: auto;
	margin-right: auto;
}

.PopUpDate {
	gap: 30px;
}

.PopUpDate .AlertContent {
	font-weight: 600;
	font-size: 20px;
}

.PopUpGroupButton {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	gap: 10px;
}

.BoxAddress {
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-top: 12px;
}

.BoxAddress svg {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 25px;
	color: #888;
	flex: 0 0 16px;
}

.BoxAddress p {
	margin-top: auto;
	margin-bottom: auto;
}

.OpenDesc {
	font-size: 14px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 5px
}

.OpenDesc svg {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 16px;
}

.CartSubTotal {
	margin-top: auto;
}

.CartSubTotal div {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	gap: 10px;
}

.CartSubTotal .SubTotalNum {
	margin-left: auto;
}

@media (max-width: 992px) {
	.CollapseInfo {
		display: unset;
	}

	.CollapseWrapper {
		display: unset;
	}

	.SplitPanel {
		flex-direction: column;
	}

	.LeftPanel {
		width: 100%;
	}

	.RightPanel {
		display: none;
	}

	.FloatCartIcon {
		display: flex;
		animation: showRight 500ms;
	}

	.DateSeatMap {
		flex-direction: column;
		gap: 10px;
		text-align: center;
	}

	.DateSeatMap button {
		margin-left: unset !important;
	}

	.Navigation {
		-ms-overflow-style: none;
		/* for Internet Explorer, Edge */
		scrollbar-width: none;
		/* for Firefox */
		overflow-x: auto;
		top: 70px;
	}

	.Navigation::-webkit-scrollbar {
		display: none;
	}
}

.EmbedNavigation {
	display: flex;
	flex-direction: row;
	gap: 5px;
	height: 80px;
	left: 0px;
	position: fixed;
	z-index: 2;
	width: 100%;
	background-color: #fff;
	padding: 0 20px;
}

.BoxEmbedNav {
	width: calc(25% - 3.7px);
	display: flex;
	flex-direction: row;
	gap: 5px;
	flex-wrap: wrap;
	opacity: 0.5;
}

.BoxEmbedNavActive {
	opacity: 1;
}

.BoxEmbedNav img,
.BoxEmbedNav b {
	margin-top: auto;
	margin-bottom: auto;
}

.BoxEmbedNav b {
	margin-right: auto;
	font-size: 14px;
}

.BoxEmbedNav img {
	width: 25px;
	height: 25px;
	margin-left: auto;
}

.EmbedFooter {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	padding-left: 35px;
	padding-right: 35px;
	padding-bottom: 20px;
	/* padding-top: 20px; */
	background-color: #fff;
	box-shadow: 0px 2px 20px #00000021;
}

.CollapseFooter {
	position: fixed;
	bottom: 0;
	width: calc(100% - 20px);
	max-width: 419px;
	right: 0;
	padding-left: 35px;
	padding-right: 35px;
	padding-bottom: 20px;
	/* padding-top: 20px; */
	background-color: #fff;
	box-shadow: 0px 2px 20px #00000021;
}

/* @media (max-width: 450px) {
	.DateSelectorGroup{
		flex-direction: column;
	}
	.DateSelectorGroup button{
		margin-left: auto;
		margin-right: auto;
		width: 100% !important;
	}
} */
@media (max-width: 1200px) {
	.FloatCartIconEmbed {
		width: calc(100% - 78px);
	}
}

@media (max-width: 772px) {
	.TicketSplit {
		flex-direction: column;
	}

	.CoverBox {
		width: 100%;
		padding: 16px 24px 12px 20px;
	}

	.CoverBox img {
		width: 50%;
	}
}

@media (max-width: 570px) {
	.BoxEmbedNav {
		width: calc(25% - 3.7px);
		display: flex;
		flex-direction: column;
		gap: 5px;
		flex-wrap: wrap;
	}

	.BoxEmbedNav img,
	.BoxEmbedNav b {
		margin-top: unset;
		margin-bottom: unset;
		margin-left: auto;
		margin-right: auto;
	}

	.BoxEmbedNav b {
		margin-bottom: auto;
		font-size: 13px;
	}

	.BoxEmbedNav img {
		width: 15px;
		height: 15px;
		margin-top: auto;
	}
}

@media (max-width: 450px) {
	.MainBanner {
		margin-left: -40px;
		margin-right: -40px;
		border-radius: 0px;
		width: unset;
	}

	.MainBanner img {
		border-radius: 0px;
	}

	.RightInfo {
		margin-left: -40px;
		margin-right: -40px;
		width: unset;
		box-shadow: unset;
		padding-bottom: 24px;
		border-bottom: 10px solid #f7f7f7;
		border-radius: 0px;
	}

	.FloatCartIconEmbed {
		width: calc(100% - 48px);
		left: 24px;
	}
}

@media (max-width: 420px) {

	/* .Time {
		flex-direction: column;
		gap: 5px;
	}
	.Time .Clock {
		margin-left: unset;
	} */
	.Time .Clock {
		margin-left: 5px;
	}
}

@media (max-width: 307px) {
	.BoxEmbedNav b {
		margin-bottom: auto;
		font-size: 12px;
		font-weight: 400;
	}
}